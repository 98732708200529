

































































































import {Component, Prop, Vue} from "vue-property-decorator";
import ITRHadInsuranceDTO from "@/dto/taxation/individual/ITRHadInsuranceDTO";

@Component
export default class ITRInsuranceCard extends Vue {

  @Prop()
  private hadInsurance!: ITRHadInsuranceDTO;

}
