export enum IndividualTaxationStep {
    Q_INIT = 'Q_INIT',
    OCCUPATION = 'OCCUPATION',
    ADDRESS = 'ADDRESS',
    MARITAL_STATUS = 'MARITAL_STATUS',
    SPOUSE = 'SPOUSE',
    SPOUSE_ID_CARD = "SPOUSE_ID_CARD",
    DEPENDENTS_NUM = 'DEPENDENTS_NUM',
    BANK_ACCOUNT = 'BANK_ACCOUNT',
    ECONOMIC_IMPACT = 'ECONOMIC_IMPACT',
    IS_UNEMPLOYED = 'IS_UNEMPLOYED',
    HAD_INCOME = 'HAD_INCOME',
    HAD_EXPENSES = 'HAD_EXPENSES',
    HAD_UNIVERSITY = 'HAD_UNIVERSITY',
    HAD_INSURANCE = 'HAD_INSURANCE',
    HAD_FOREIGN_BANK_ACCOUNT = 'HAD_FOREIGN_BANK_ACCOUNT',
    HAD_CRYPTO = 'HAD_CRYPTO',
    HAD_TRADE = 'HAD_TRADE',
    HAD_IRS_LETTERS = 'HAD_IRS_LETTERS',
    Q_FILES = 'Q_FILES',
    Q_CONFIRM = 'Q_CONFIRM',
    Q_FINISHED = 'Q_FINISHED'
}