



























































import {Component, Prop, Vue} from "vue-property-decorator";
import ValuableCloneableDTO from "@/dto/taxation/individual/ValuableCloneableDTO";
import {MaritalStatus} from "@/dto/taxation/individual/MaritalStatus";
import {MARITAL_STATUSES} from "@/constants/ProfileConstants";

@Component
export default class ITRMaritalStatusCard extends Vue {

  private maritalStatuses = MARITAL_STATUSES

  @Prop()
  private element!: ValuableCloneableDTO<MaritalStatus>;

  @Prop()
  private period!: string;

  private inputValue: MaritalStatus | null = this.element.value;

  private isStatementTrue: boolean | null = null;

  get isElementPresent() {
    return this.inputValue != null;
  }

}
