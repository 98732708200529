import axios, {AxiosResponse} from 'axios';
import {
    TAXATION_INDIVIDUAL_QUESTIONNAIRE_PAGES_COUNT_ENDPOINT,
    TAXATION_INDIVIDUAL_QUESTIONNAIRE_ENDPOINT,
    TAXATION_INDIVIDUAL_QUESTIONNAIRE_LIST_ENDPOINT,
    TAXATION_INDIVIDUAL_QUESTIONNAIRE_PUBLIC_ENDPOINT,
    TAXATION_INDIVIDUAL_BATCH_UPDATE_JOB_TABLE_ENDPOINT,
    TAXATION_INDIVIDUAL_BATCH_CREATE_QUESTIONNAIRES_ENDPOINT,
    TAXATION_INDIVIDUAL_BATCH_SEND_NOTIFICATIONS_ENDPOINT
} from '@/constants/endpoints'
import authHeader from "@/services/auth-header";
import IndividualTaxationQuestionnaireDTO from "@/dto/taxation/individual/IndividualTaxationQuestionnaireDTO";
import UpdateIndividualQuestionnaireRequestDTO from "@/dto/taxation/individual/UpdateIndividualQuestionnaireRequestDTO";
import CreateIndividualQuestionnaireRequestDTO from "@/dto/taxation/individual/CreateIndividualQuestionnaireRequestDTO";
import IndividualQuestionnaireFilter from "@/dto/taxation/individual/IndividualQuestionnaireFilter";
import IndividualTaxationQuestionnaireShortDataDTO
    from "@/dto/taxation/individual/IndividualTaxationQuestionnaireShortDataDTO";
import QueryUtils from "@/utils/QueryUtils";
import CollectionUtils from "@/utils/CollectionUtils";

class IndividualTaxationServiceOld {

    getQuestionnaireById(id: number) {
        return axios.get<IndividualTaxationQuestionnaireDTO>(`${TAXATION_INDIVIDUAL_QUESTIONNAIRE_ENDPOINT}/${id}`, {headers: authHeader()});
    }

    updateQuestionnaire(request: UpdateIndividualQuestionnaireRequestDTO) {
        return axios.post<IndividualTaxationQuestionnaireDTO>(`${TAXATION_INDIVIDUAL_QUESTIONNAIRE_ENDPOINT}`, request, {headers: authHeader()});
    }

    createQuestionnaire(request: CreateIndividualQuestionnaireRequestDTO) {
        return Promise.reject<AxiosResponse<IndividualTaxationQuestionnaireDTO>>()
        // return axios.put<IndividualTaxationQuestionnaireDTO>(`${TAXATION_INDIVIDUAL_QUESTIONNAIRE_ENDPOINT}`, request, {headers: authHeader()});
    }

    getQuestionnaireByPublicId(id: string) {
        return axios.get<IndividualTaxationQuestionnaireDTO>(`${TAXATION_INDIVIDUAL_QUESTIONNAIRE_PUBLIC_ENDPOINT}/${id}`);
    }

    updatePublicQuestionnaire(request: UpdateIndividualQuestionnaireRequestDTO) {
        return axios.post<IndividualTaxationQuestionnaireDTO>(`${TAXATION_INDIVIDUAL_QUESTIONNAIRE_PUBLIC_ENDPOINT}`, request);
    }

    getQuestionnaires(filter: IndividualQuestionnaireFilter) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createPathParamsMap(
                ["personId", filter.personId],
                ["customer", filter.customer],
                ["period", filter.period],
                ["assigneeId", filter.assigneeId],
                ["pageNumber", filter.pageNumber],
                ["pageSize", filter.pageSize]));
        return axios.get<Array<IndividualTaxationQuestionnaireShortDataDTO>>(
            `${TAXATION_INDIVIDUAL_QUESTIONNAIRE_ENDPOINT}${queryString}`,
            {headers: authHeader()});
    }

    countPages(filter: IndividualQuestionnaireFilter) {
        return axios.post<number>(`${TAXATION_INDIVIDUAL_QUESTIONNAIRE_PAGES_COUNT_ENDPOINT}`, filter, {headers: authHeader()});
    }

    updateJobTable() {
        return axios.post<void>(TAXATION_INDIVIDUAL_BATCH_UPDATE_JOB_TABLE_ENDPOINT, {}, {headers: authHeader()});
    }

    createQuestionnaires() {
        return axios.post<void>(TAXATION_INDIVIDUAL_BATCH_CREATE_QUESTIONNAIRES_ENDPOINT, {}, {headers: authHeader()});
    }

    sendNotifications() {
        return axios.post<void>(TAXATION_INDIVIDUAL_BATCH_SEND_NOTIFICATIONS_ENDPOINT, {}, {headers: authHeader()});
    }
}

export default new IndividualTaxationServiceOld();