

































































import {Component, Prop, Vue} from "vue-property-decorator";
import ITRHadForeignBankAccountDTO from "@/dto/taxation/individual/ITRHadForeignBankAccountDTO";

@Component
export default class ITRForeignBankAccountCard extends Vue {

  @Prop()
  private foreignBankAccount!: ITRHadForeignBankAccountDTO;

}
