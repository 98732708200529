







































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {ITRHadIncomeDTO} from "@/dto/taxation/individual/ITRHadIncomeDTO";

@Component
export default class ITRIncomeFormsPresenceCard extends Vue {

  @Prop()
  private hadIncome!: ITRHadIncomeDTO;

  handleInput () {
      this.hadIncome.value = this.hadIncome.rentalIncome
          || this.hadIncome.juryDutyIncome
          || this.hadIncome.fw2g
          || this.hadIncome.f1099k
          || this.hadIncome.f1099b
          || this.hadIncome.fk1
          || this.hadIncome.alimonyReceived
          || this.hadIncome.f1099div
          || this.hadIncome.f1099int
          || this.hadIncome.f1099miscnec
          || this.hadIncome.fw2
          || this.hadIncome.f1099ssa
          || this.hadIncome.f1099r
          || this.hadIncome.etc
  }

}
