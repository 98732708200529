



















































































































































































































































































































































































import {Component, Vue} from "vue-property-decorator";
import IndividualTaxationQuestionnaireDTO from "@/dto/taxation/individual/IndividualTaxationQuestionnaireDTO";
import IndividualTaxationService from "@/services/IndividualTaxationServiceOld";
import UpdateIndividualQuestionnaireRequestDTO from "@/dto/taxation/individual/UpdateIndividualQuestionnaireRequestDTO";
import {IndividualTaxationStep} from "@/dto/taxation/individual/IndividualTaxationStep";
import SkippableDTO from "@/dto/taxation/individual/SkippableDTO";
import ValuableDTO from "@/dto/taxation/individual/ValuableDTO";
import {MARITAL_STATUSES, STATE_LIST} from "@/constants/ProfileConstants";
import ITRAddressDTO from "@/dto/taxation/individual/ITRAddressDTO";
import RegexUtils from "@/utils/RegexUtils";
import {MaritalStatus} from "@/dto/taxation/individual/MaritalStatus";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import IndividualTaxationDocumentDTO from "@/dto/files/IndividualTaxationDocumentDTO";
import {namespace} from "vuex-class";
import RouteNames from "@/router/RouteNames";
import ITRValuableCloneableCard from "@/components/taxation/individual/questionnaire/ITRValuableCloneableCard.vue";
import ITRAddressCard from "@/components/taxation/individual/questionnaire/ITRAddressCard.vue";
import ITRMaritalStatusCard from "@/components/taxation/individual/questionnaire/ITRMaritalStatusCard.vue";
import ITRSpouseCard from "@/components/taxation/individual/questionnaire/ITRSpouseCard.vue";
import ITRSpouseDTO from "@/dto/taxation/individual/ITRSpouseDTO";
import ITROccupationCard from "@/components/taxation/individual/questionnaire/ITROccupationCard.vue";
import ITRDependentCard from "@/components/taxation/individual/questionnaire/ITRDependentCard.vue";
import ITRBankAccountCard from "@/components/taxation/individual/questionnaire/ITRBankAccountCard.vue";
import ITRSimpleQuestionCard from "@/components/taxation/individual/questionnaire/ITRSimpleQuestionCard.vue";
import ITRIncomeFormsPresenceCard from "@/components/taxation/individual/questionnaire/ITRIncomeFormsPresenceCard.vue";
import ITRExpensesFormsPresenceCard from "@/components/taxation/individual/questionnaire/ITRExpensesFormsPresenceCard.vue";
import ITRInsuranceCard from "@/components/taxation/individual/questionnaire/ITRInsuranceCard.vue";
import ITRForeignBankAccountCard from "@/components/taxation/individual/questionnaire/ITRForeignBankAccountCard.vue";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import ITRSpouseIDCard from "@/components/taxation/individual/questionnaire/ITRSpouseIDCard.vue";
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;
import {validate} from "@/utils/ComponentUtils";
import IndividualTaxationServiceOld from "@/services/IndividualTaxationServiceOld";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: {
        ITRSpouseIDCard,
        ITRForeignBankAccountCard,
        ITRInsuranceCard,
        ITRExpensesFormsPresenceCard,
        ITRIncomeFormsPresenceCard,
        ITRSimpleQuestionCard,
        ITRBankAccountCard,
        ITRDependentCard,
        ITROccupationCard,
        ITRSpouseCard, ITRMaritalStatusCard, ITRAddressCard, ITRValuableCloneableCard, FileUpload}
})
export default class IndividualQuestionnaire extends Vue {

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    @Auth.Getter("hasAdministrativePrivileges")
    private hasAdministrativePrivileges!: boolean;

    private maritalStatuses = MARITAL_STATUSES

    private states = STATE_LIST

    private questionnaire: IndividualTaxationQuestionnaireDTO | null = null;

    private current = new SkippableDTO();

    private booleanValuable = new ValuableDTO<boolean>();

    private haveDependents!: boolean;

    private spouse = new ITRSpouseDTO();

    private address: ITRAddressDTO = new ITRAddressDTO();

    private label = "";

    private editStage: IndividualTaxationStep | null = null;

    private stepSingleton = IndividualTaxationStep;

    private maritalStatusSingleton = MaritalStatus;

    private uploadTips: Array<TranslateResult> = [];

    private successful = false;

    private message = "";

    mounted() {
        this.loadQuestionnaire();
    }

    created(){
        this.$validator.extend(
            'greaterThanZero',{
                getMessage: field =>  field + ' needs to be > zero.',
                validate: (value) => {
                    return value > 0;
                }
            });
    }

    loadQuestionnaire(){
        const loadFun = this.$route.name == RouteNames.TAXATION_INDIVIDUAL_QUESTIONNAIRE_PUBLIC
            ? () => IndividualTaxationService.getQuestionnaireByPublicId(this.$route.params.id)
            : () => IndividualTaxationService.getQuestionnaireById(Number.parseInt(this.$route.params.id, 10));
        loadFun().then(
            response => {
                this.questionnaire = response.data;
                this.updateState(this.questionnaire?.currentStep);
            },
            error => {
                console.log(error);
            }
        );
    }

    skip(){
        this.current.isSkipped = true;
        this.booleanValuable.value = null;
        this.save(1);
        this.editStage = null;
    }

    isSpouseDataUpload() : boolean {
        if (this.questionnaire?.currentStep == IndividualTaxationStep.SPOUSE) {
            return this.questionnaire.spouse?.phone != null
                && this.questionnaire.spouse?.phone.length > 7;
        }

        if (this.questionnaire?.currentStep == IndividualTaxationStep.SPOUSE_ID_CARD) {
            return !!this.questionnaire.spouse?.idCardBack && !!this.questionnaire.spouse?.idCardBack;
        }

        return true;
    }

    goNext() {
        if (this.$children.length < 1) {
            this.save(this.editStage ? 0 : 1);
        } else {
			validate(this).then((isValid) => {
                this.message = "";
                if (isValid && this.isSpouseDataUpload()) {
                    this.save(this.editStage ? 0 : 1);
                }else {
                    this.successful = false;
                    this.message =  "Fill required fields";
                }
            });
        }
    }

    goBack() {
        this.save(-1)
    }

    save(stepDirection: number){
        this.startLoading();
        IndividualTaxationServiceOld.updatePublicQuestionnaire(
            new UpdateIndividualQuestionnaireRequestDTO(this.questionnaire as IndividualTaxationQuestionnaireDTO,
                this.editStage ? this.editStage : this.questionnaire?.currentStep as IndividualTaxationStep,
                this.editStage && this.questionnaire?.currentStep == IndividualTaxationStep.Q_FINISHED ? -1 : stepDirection)).then(
            response => {
                this.questionnaire = response.data;
                this.updateState(this.questionnaire?.currentStep);
                this.stopLoading();
            },
            error => {
                this.errorHandle(error)
                this.stopLoading();
            }
        );
        this.editStage = null;
    }

    errorHandle(error: any) {
        this.successful = false;
        const param = error?.response?.data?.param ? { param: error?.response?.data?.param } : {}
        this.message = this.$t(ErrorLocalePathUtils.getPathForError(error), param) as string;
    }

    yes(){
        this.booleanValuable.value = true;
        this.goNext();
    }

    no(){
        this.booleanValuable.value = false;
        this.goNext();
    }

    get isEditStage(){
        return !!this.editStage;
    }

    get initStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.Q_INIT;
    }

    get occupationStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.OCCUPATION || this.editStage == IndividualTaxationStep.OCCUPATION;
    }

    get addressStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.ADDRESS || this.editStage == IndividualTaxationStep.ADDRESS;
    }

    get maritalStatusStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.MARITAL_STATUS || this.editStage == IndividualTaxationStep.MARITAL_STATUS;
    }

    formatString(line: string) {
        if (line == null) {
            return "";
        }
        let firstLetter = line.substr(0, 1);
        let otherPhrase = line.substr(1).toLocaleLowerCase();
        return (firstLetter + otherPhrase).replace("_", " ");
    }

    get spouseStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.SPOUSE || this.editStage == IndividualTaxationStep.SPOUSE;
    }

    get spouseIdCardStage() {
        return this.questionnaire?.currentStep == IndividualTaxationStep.SPOUSE_ID_CARD || this.editStage == IndividualTaxationStep.SPOUSE_ID_CARD;
    }

    get dependentsStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.DEPENDENTS_NUM || this.editStage == IndividualTaxationStep.DEPENDENTS_NUM;
    }

    get bankAccountStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.BANK_ACCOUNT || this.editStage == IndividualTaxationStep.BANK_ACCOUNT;
    }

    get economicImpactStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.ECONOMIC_IMPACT || this.editStage == IndividualTaxationStep.ECONOMIC_IMPACT;
    }

    get unemployedStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.IS_UNEMPLOYED || this.editStage == IndividualTaxationStep.IS_UNEMPLOYED;
    }

    get incomeStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.HAD_INCOME || this.editStage == IndividualTaxationStep.HAD_INCOME;
    }

    get expensesStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.HAD_EXPENSES || this.editStage == IndividualTaxationStep.HAD_EXPENSES;
    }

    get universityStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.HAD_UNIVERSITY || this.editStage == IndividualTaxationStep.HAD_UNIVERSITY;
    }

    get insuranceStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.HAD_INSURANCE || this.editStage == IndividualTaxationStep.HAD_INSURANCE;
    }

    get foreignBankAccountStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.HAD_FOREIGN_BANK_ACCOUNT || this.editStage == IndividualTaxationStep.HAD_FOREIGN_BANK_ACCOUNT;
    }

    get cryptoStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.HAD_CRYPTO || this.editStage == IndividualTaxationStep.HAD_CRYPTO;
    }

    get tradeStage() {
        return this.questionnaire?.currentStep == IndividualTaxationStep.HAD_TRADE || this.editStage == IndividualTaxationStep.HAD_TRADE;
    }

    get irsLettersStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.HAD_IRS_LETTERS || this.editStage == IndividualTaxationStep.HAD_IRS_LETTERS;
    }

    get confirmStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.Q_CONFIRM && !this.isEditStage;
    }

    get fileStage(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.Q_FILES;
    }

    get isFinished(){
        return this.questionnaire?.currentStep == IndividualTaxationStep.Q_FINISHED
    }

    get showNext(){
        return this.occupationStage || this.maritalStatusStage || this.dependentsStage || this.bankAccountStage || this.spouseStage;
    }

    get showYesNo(){
        return !this.initStage && !this.confirmStage && !this.fileStage;
    }

    get showEdit(){
        return this.booleanValuable.value == false; // prevent not null -> true
    }

    setPseudoBoolean(){
        this.booleanValuable = new ValuableDTO<boolean>();
    }

    setInitLabel(){
        this.label = "**Please go through the questionnaire**";
    }

    setOccupationLabel(){
        this.label = "**Is your occupation " + this.questionnaire?.occupation.value + "?"
    }

    setAddressLabel(){
        this.label = `Is your address ${this.assembleAddress(this.address)}?`
    }

    assembleAddress(address: ITRAddressDTO) {
      return address ? address.residental
              ? address.address1 + " " + address.city + " " + address.state + " " + address.zipCode
              : address.foreignAddress + " " + address.city + " " + address.zipCode + " " + address.zipCode
          : "";
    }

    setMaritalStatusLabel(){
        this.label = `Is your marital status "${this.questionnaire?.maritalStatus.value}"?`
    }

    setSpouseLabel(){
        this.label = "Please check your spouse data"
    }

    setDependentsLabel(){
        this.label = `Do you have any dependents?`;
    }

    setBankAccountLabel(){
        this.label = "Check your bank account data";
    }

    setEconomicImpactLabel(){
        this.label = "**Did you receive a third round Economic Impact Payment (EIP) - $1400?**";
    }

    setUnemployedLabel(){
        this.label = "**Are you unemployed?**";
    }

    setIncomeLabel(){
        this.label = "**Do you have income forms/documents such as:";
    }

    setExpensesLabel(){
        this.label = "**Do you have expenses forms/documents such as:";
    }

    setUniversityLabel(){
        this.label = " **Did you study at university?**";
    }

    setInsuranceLabel(){
        this.label = " **Do you have insurance?**";
    }

    setForeignBankAccountsLabel(){
        this.label = " **Do you have foreign bank accounts?**";
    }

    setCryptoLabel(){
        this.label = "**Do you have cryptocurrency?**";
    }

    setTradeLabel() {
        this.label = "**Do you have trade accounts?**"
    }

    setIrsLettersLabel(){
        this.label = "**Do you have any IRS letters?**";
    }

    setEmptyLabel(){
        this.label = "";
    }

    setEditStage(step: IndividualTaxationStep){
        this.editStage = step;
        this.updateState(step);
    }

    private updateState(step: IndividualTaxationStep){
        if(!this.questionnaire){
            return;
        }
        switch (step){
            case IndividualTaxationStep.Q_INIT:
                this.setInitLabel();
                break;
            case IndividualTaxationStep.OCCUPATION:
                this.current = this.questionnaire?.occupation;
                this.setPseudoBoolean();
                this.setOccupationLabel();
                break;
            case IndividualTaxationStep.ADDRESS:
                if(this.questionnaire?.addresses.length != 0){
                    this.address = this.questionnaire?.addresses[0];
                }else {
                    this.address = new ITRAddressDTO();
                }
                this.setPseudoBoolean();
                this.setAddressLabel();
                break;
            case IndividualTaxationStep.MARITAL_STATUS:
                this.current = this.questionnaire?.maritalStatus;
                this.setMaritalStatusLabel();
                this.setPseudoBoolean();
                break;
            case IndividualTaxationStep.SPOUSE:
                if(this.questionnaire?.spouse){
                    this.spouse = this.questionnaire.spouse;
                }
                this.setPseudoBoolean();
                this.setSpouseLabel();
                break;
            case IndividualTaxationStep.DEPENDENTS_NUM:
                this.haveDependents = this.questionnaire.dependentsNum.haveDependents;
                this.setDependentsLabel();
                this.setPseudoBoolean();
                break;
            case IndividualTaxationStep.BANK_ACCOUNT:
                this.current = this.questionnaire?.bankAccount;
                this.setPseudoBoolean();
                this.setBankAccountLabel();
                break;
            case IndividualTaxationStep.ECONOMIC_IMPACT:
                this.current = this.questionnaire?.economicImpact;
                this.booleanValuable = this.questionnaire?.economicImpact;
                this.setEconomicImpactLabel();
                break;
            case IndividualTaxationStep.IS_UNEMPLOYED:
                this.current = this.questionnaire?.isUnemployed;
                this.booleanValuable = this.questionnaire?.isUnemployed;
                this.setUnemployedLabel();
                break;
            case IndividualTaxationStep.HAD_INCOME:
                this.current = this.questionnaire?.hadIncome;
                this.booleanValuable = this.questionnaire?.hadIncome;
                this.setIncomeLabel();
                break;
            case IndividualTaxationStep.HAD_EXPENSES:
                this.current = this.questionnaire?.hadExpenses;
                this.booleanValuable = this.questionnaire?.hadExpenses;
                this.setExpensesLabel();
                break;
            case IndividualTaxationStep.HAD_UNIVERSITY:
                this.current = this.questionnaire?.hadUniversity;
                this.booleanValuable = this.questionnaire?.hadUniversity;
                this.setUniversityLabel();
                break;
            case IndividualTaxationStep.HAD_INSURANCE:
                this.current = this.questionnaire?.hadInsurance;
                this.booleanValuable = this.questionnaire?.hadInsurance;
                this.setInsuranceLabel();
                break;
            case IndividualTaxationStep.HAD_FOREIGN_BANK_ACCOUNT:
                this.current = this.questionnaire?.hadForeignBankAccount;
                this.booleanValuable = this.questionnaire?.hadForeignBankAccount;
                this.setForeignBankAccountsLabel();
                break;
            case IndividualTaxationStep.HAD_CRYPTO:
                this.current = this.questionnaire?.hadCryptocurrency;
                this.booleanValuable = this.questionnaire?.hadCryptocurrency;
                this.setCryptoLabel();
                break;
            case IndividualTaxationStep.HAD_TRADE:
                this.current = this.questionnaire?.hadTradeAccount;
                this.booleanValuable = this.questionnaire?.hadTradeAccount;
                this.setTradeLabel();
                break;
            case IndividualTaxationStep.HAD_IRS_LETTERS:
                this.current = this.questionnaire?.hadIrsLetters;
                this.booleanValuable = this.questionnaire?.hadIrsLetters;
                this.setIrsLettersLabel();
                break;
            case IndividualTaxationStep.Q_CONFIRM:
                this.setEmptyLabel();
                break;
            case IndividualTaxationStep.Q_FILES:
                this.updateUploadTips();
                this.setEmptyLabel();
                break;
            case IndividualTaxationStep.Q_FINISHED:
                this.setEmptyLabel();
                break;
        }
    }

    handleAddress(event: KeyboardEvent){
        if(!RegexUtils.testAddressCharacters(event.key)){
            event.preventDefault();
        }
    }

    handleZip(event: KeyboardEvent) {
        if(!RegexUtils.testNumeric(event.key)){
            event.preventDefault();
        }
    }

    onFilesUploaded(files: FileMetaDTO[]){
        files.forEach(file => this.questionnaire?.documents.push(new IndividualTaxationDocumentDTO(this.questionnaire?.id as number, file)))
    }

    private updateUploadTips() {
        this.uploadTips = [];
        this.uploadTips.push(this.$t("taxation.questioner.id_card_or_driver"));
        if(this.questionnaire?.hadIncome.fw2) this.uploadTips.push(this.$t("taxation.questioner.income.w2"));
        if(this.questionnaire?.hadIncome.f1099miscnec) this.uploadTips.push(this.$t("taxation.questioner.income.1099_misc"));
        if(this.questionnaire?.hadIncome.fk1) this.uploadTips.push(this.$t("taxation.questioner.income.k_1"));
        if(this.questionnaire?.hadIncome.f1099int) this.uploadTips.push(this.$t("taxation.questioner.income.1099_int"));
        if(this.questionnaire?.hadIncome.f1099div) this.uploadTips.push(this.$t("taxation.questioner.income.1099_div"));
        if(this.questionnaire?.hadIncome.f1099ssa) this.uploadTips.push(this.$t("taxation.questioner.income.ssa_1099"));
        if(this.questionnaire?.hadIncome.f1099r) this.uploadTips.push(this.$t("taxation.questioner.income.1099_r"));
        if(this.questionnaire?.hadIncome.f1099b) this.uploadTips.push(this.$t("taxation.questioner.income.1099_b"));
        if(this.questionnaire?.hadIncome.fw2g) this.uploadTips.push(this.$t("taxation.questioner.income.w_2g"));
        if(this.questionnaire?.hadIncome.alimonyReceived) this.uploadTips.push(this.$t("taxation.questioner.income.alimony"));
        if(this.questionnaire?.hadIncome.juryDutyIncome) this.uploadTips.push(this.$t("taxation.questioner.income.jury_duty"));
        if(this.questionnaire?.hadIncome.rentalIncome) this.uploadTips.push(this.$t("taxation.questioner.income.rental"));
        if(this.questionnaire?.hadIncome.etc) this.uploadTips.push(this.$t("taxation.questioner.income.other"));
        if(this.questionnaire?.hadExpenses.f1098) this.uploadTips.push(this.$t("taxation.questioner.following_forms.1098"));
        if(this.questionnaire?.hadExpenses.f5498ira) this.uploadTips.push(this.$t("taxation.questioner.following_forms.5498"));
        if(this.questionnaire?.hadExpenses.health) this.uploadTips.push(this.$t("taxation.questioner.following_forms.health"));
        if(this.questionnaire?.hadInsurance.value && this.questionnaire.hadInsurance.haveForms)
            this.uploadTips.push(this.questionnaire.hadInsurance.isFree ? this.$t("taxation.questioner.medical_insurance.1095b") : this.$t("taxation.questioner.medical_insurance.1095ac"));
        if(this.questionnaire?.hadCryptocurrency.value) this.uploadTips.push(this.$t("taxation.questioner.income.consolidated"));
        if(this.questionnaire?.hadIrsLetters.value) this.uploadTips.push(this.$t("taxation.questioner.irs_letters"));
    }

    downloadConfirmation(){
        const file = this.questionnaire?.confirmation?.signedDocument;
        if(file){
            Vue.prototype.$docUtils.download(file.id);
        }
    }

}

