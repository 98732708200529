import IndividualTaxationQuestionnaireDTO from "@/dto/taxation/individual/IndividualTaxationQuestionnaireDTO";
import {IndividualTaxationStep} from "@/dto/taxation/individual/IndividualTaxationStep";

export default class UpdateIndividualQuestionnaireRequestDTO {

    private questionnaire!: IndividualTaxationQuestionnaireDTO;

    private step!: IndividualTaxationStep;

    private stepDirection!: number;

    constructor(questionnaire: IndividualTaxationQuestionnaireDTO, step: IndividualTaxationStep, stepDirection: number) {
        this.questionnaire = questionnaire;
        this.step = step;
        this.stepDirection = stepDirection;
    }
}