














































































































import {Component, Prop, Vue} from "vue-property-decorator";
import IndividualTaxationQuestionnaireDTO from "@/dto/taxation/individual/IndividualTaxationQuestionnaireDTO";
import ITRSpouseDTO from "@/dto/taxation/individual/ITRSpouseDTO";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import FileUpload from "@/components/common/FileUpload.vue";

@Component({
    components: {FileUpload}
})
export default class ITRSpouseIDCard extends Vue{
    @Prop()
    private questionnaire!: IndividualTaxationQuestionnaireDTO;

    private isInputElementPresent = false;

    private spouse: ITRSpouseDTO = this.getOrBuildSpouse;

    private isStatementTrue: boolean | null = null;

    get getOrBuildSpouse(): ITRSpouseDTO {
        if (this.questionnaire.spouse) {
            return this.questionnaire.spouse;
        } else {
            let spouse  = new ITRSpouseDTO();
            this.questionnaire.spouse = spouse;
            return spouse;
        }
    }

    get isEmptyFields() : boolean {
        return !this.spouse.idCardFront
            || !this.spouse.idCardBack;
    }

    mounted() {
        this.isInputElementPresent = !!this.questionnaire.spouse
            && !!this.spouse.idCardFront
            && !!this.spouse.idCardBack
    }

    handleFrontCard(fileMetaDTOs: FileMetaDTO[]) {
        if (fileMetaDTOs.length < 1) {
            return;
        }
        this.spouse.idCardFront = fileMetaDTOs[0];
    }

    handleBackCard(fileMetaDTOs: FileMetaDTO[]) {
        if (fileMetaDTOs.length < 1) {
            return;
        }
        this.spouse.idCardBack = fileMetaDTOs[0];
    }

    handleNo() {
        this.spouse.idCardBack = null;
        this.spouse.idCardFront = null;
        this.isStatementTrue = false;
    }
}
