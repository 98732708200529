

















































import {Component, Prop, Vue} from "vue-property-decorator";
import ValuableCloneableDTO from "@/dto/taxation/individual/ValuableCloneableDTO";

@Component
export default class ITRValuableCloneableCard<T> extends Vue {

  @Prop()
  private element!: ValuableCloneableDTO<T>;

  @Prop({default: 'text'})
  private inputType!: string;

  @Prop()
  private checkActualityLabel!: string;

  @Prop()
  private actualizationLabel!: string;

  @Prop()
  private initLabel!: string;

  private inputValue: T | null = this.element.value;

  private isStatementTrue: boolean | null = null;

  get isElementPresent() {
    return this.inputValue != null;
  }

}
