export default class ITRAddressDTO {

    public  id: number | null = null;

    public  addressNum: number | null = null;

    public residental = true;

    public  state = "";

    public  city = "";

    public  address1 = "";

    public address2 = "";

    public  zipCode = "";

    public  country = "";

    public  foreignAddress = "";

    public  isCloned: boolean | null = null;

    public  isSkipped: boolean | null = null;
}