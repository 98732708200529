import { render, staticRenderFns } from "./ITRMaritalStatusCard.vue?vue&type=template&id=58374294&scoped=true&"
import script from "./ITRMaritalStatusCard.vue?vue&type=script&lang=ts&"
export * from "./ITRMaritalStatusCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58374294",
  null
  
)

export default component.exports