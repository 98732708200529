





































import {Component, Prop, Vue} from "vue-property-decorator";
import DependentDTO from "@/dto/taxation/individual/DependentDTO";

@Component
export default class ITROccupationCard extends Vue {
  @Prop()
  private element!: DependentDTO;
  private inputValue: DependentDTO = this.element;
  private isStatementTrue: boolean | null = null;

}
