























































import {Component, Prop, Vue} from "vue-property-decorator";
import ValuableCloneableDTO from "@/dto/taxation/individual/ValuableCloneableDTO";

@Component
export default class ITROccupationCard extends Vue {

    @Prop()
    private element!: ValuableCloneableDTO<string>;

    private inputValue: string | null = this.element.value;

    private isStatementTrue: boolean | null = null;

    get isElementPresent() {
        return this.inputValue != null;
    }

}
