



















































































import {Component, Prop, Vue} from "vue-property-decorator";
import ValuableCloneableDTO from "@/dto/taxation/individual/ValuableCloneableDTO";
import ITRSpouseDTO from "@/dto/taxation/individual/ITRSpouseDTO";
import IndividualTaxationQuestionnaireDTO from "@/dto/taxation/individual/IndividualTaxationQuestionnaireDTO";
import RegexUtils from "@/utils/RegexUtils";
import ITRBankAccountDTO from "@/dto/taxation/individual/ITRBankAccountDTO";

@Component
export default class ITRBankAccountCard extends Vue {

    @Prop()
    private element!: ITRBankAccountDTO;

    private isStatementTrue: boolean | null = null;

    private isInputElementPresent = !!(this.element.id && this.element.accountNumber && this.element.routingNumber && this.element.accountType);

    formatString(line: string) {
        let firstLetter = line.substr(0, 1);
        let otherPhrase = line.substr(1).toLocaleLowerCase();
        return firstLetter + otherPhrase;
    }
    get getTypeName(): string {
        return this.formatString(this.element.accountType.toString().replace("_", " "))
    }

    get isEmptyFields() : boolean {
        return !this.element.accountNumber
            || !this.element.accountType
            || !this.element.routingNumber;
    }

}
