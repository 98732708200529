






















































































































import {Component, Prop, Vue} from "vue-property-decorator";
import ITRSpouseDTO from "@/dto/taxation/individual/ITRSpouseDTO";
import {MaritalStatus} from "@/dto/taxation/individual/MaritalStatus";
import IndividualTaxationQuestionnaireDTO from "@/dto/taxation/individual/IndividualTaxationQuestionnaireDTO";
import RegexUtils from "@/utils/RegexUtils";
import PhoneInput from "@/components/util/PhoneInput.vue";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import {namespace} from "vuex-class";
import UserDTO from "@/dto/UserDTO";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
    components: {FileUpload, PhoneInput}
})
export default class ITRSpouseCard extends Vue {
    @Prop()
    private questionnaire!: IndividualTaxationQuestionnaireDTO;

    private isInputElementPresent = false;

    private spouse: ITRSpouseDTO = this.getOrBuildSpouse;

    private isStatementTrue: boolean | null = null;

    mounted() {
        this.isInputElementPresent = !!this.questionnaire.spouse
            && !!this.spouse.firstName
            && !!this.spouse.lastName
            && !!this.spouse.ssn
            && !!this.spouse.email
            && !!this.spouse.phone
            && (7 < (this.spouse.phone?.length as number))
            && !!this.spouse.phone
            && !!this.spouse.occupation;
    }

    get isElementPresent() {
        return this.isInputElementPresent;
    }

    get existEmptyField(): boolean {
        return !this.spouse.firstName
            || !this.spouse.lastName
            || !this.spouse.ssn
            || !this.spouse.email
            || !this.spouse.phone
            || (7 > (this.spouse.phone?.length as number))
            || !this.spouse.ssn
            || !this.spouse.occupation
    }

    get getOrBuildSpouse(): ITRSpouseDTO {
        if (this.questionnaire.spouse) {
            return this.questionnaire.spouse;
        } else {
            let spouse  = new ITRSpouseDTO();
            this.questionnaire.spouse = spouse;
            return spouse;
        }
    }

    get isMarried(): boolean {
        return this.questionnaire.maritalStatus.value == MaritalStatus.MARRIED;
    }

    maskHandle() {
        let mask = "___-__-____";
        let oldVal = this.spouse.ssn.replace(/\D/g, "");

        if (mask.length >= this.spouse.ssn.length) {
            this.spouse.ssn = this.buildPhoneWithMask(mask, oldVal);
        } else if (mask.length < this.spouse.ssn.length){
            this.spouse.ssn = this.spouse.ssn.substr(0, mask.length)
        }
    }

    buildPhoneWithMask(mask: string, oldVal: string) {
        let i = 0;

        let newValue = this.deleteSuffixIfNeed(mask.replace(/[_\d]/g, function (a:string) {
            return i < oldVal.length ? oldVal.charAt(i++) : a
        }));

        if (newValue.length <= mask.length) {
            oldVal = newValue;
        }

        if (this.spouse.ssn.substr(oldVal.length -1) == "-") {
            newValue = oldVal.substr(0, oldVal.length);
        }

        return newValue;
    }

    handleInput(event: KeyboardEvent) {
        if (event.key != '\'' && event.key != "-" && !RegexUtils.testLatin(event.key) && event.key != ' ') {
            event.preventDefault();
        }
    }

    deleteSuffixIfNeed(newValue: string) {
        if  (newValue.indexOf("_") != -1) {
            newValue = newValue.substr(0, newValue.indexOf("_"))
        }

        if (newValue.substr(newValue.length - 1) == "-") {
            newValue = newValue.substr(0, newValue.length - 1);
        }

        let suffix = newValue.substr(newValue.length - 2);

        if (suffix == " (" || suffix == ") ") {
            newValue = newValue.substr(0, newValue.length - 2)
        }

        return newValue;
    }
}
