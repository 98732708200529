import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class IndividualTaxationDocumentDTO {

    public id: number | null = null;

    public questionnaireId!: number;

    public file!: FileMetaDTO;

    public documentType: string | null = null;

    public isPrimary = true;

    public isProcessed = false;

    public createDate = new Date();

    public updateDate: Date | null = null;

    constructor(questionnaireId: number, file: FileMetaDTO) {
        this.questionnaireId = questionnaireId;
        this.file = file;
    }


}