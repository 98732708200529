




































import {Component, Prop, Vue} from "vue-property-decorator";
import ValuableCloneableDTO from "@/dto/taxation/individual/ValuableCloneableDTO";

@Component
export default class ITRValuableCloneableCard extends Vue {

  @Prop()
  private element!: ValuableCloneableDTO<boolean>;

  mounted() {
      if (!this.element) {
          this.element = new ValuableCloneableDTO<boolean>();
      }
  }

  @Prop()
  private customLabel!: string;

}
