import FileMetaDTO from "@/dto/files/FileMetaDTO";

export default class ITRSpouseDTO {

    public id: number | null = null;

    public userId: number | null = null;

    public email: string | null = null;

    public firstName!: string;

    public lastName!: string;

    public declaredSsn: string | null = null;

    public ssn!: string;

    public declaredItin: string | null = null;

    public itin: string | null = null;

    public phone: string | null = null;

    public isClient = false;

    public isChangeSpouse = false;

    public occupation: string | null = null;

    public idCardFront: FileMetaDTO | null = null;

    public idCardBack: FileMetaDTO | null = null;

}