































import {Component, Prop, Vue} from "vue-property-decorator";
import ITRHadExpensesDTO from "@/dto/taxation/individual/ITRHasExpensesDTO";

@Component
export default class ITRIncomeFormsPresenceCard extends Vue {

    @Prop()
    private hadExpenses!: ITRHadExpensesDTO;

    handleInput() {
        this.hadExpenses.value = this.hadExpenses.health
            || this.hadExpenses.f1098
            || this.hadExpenses.f5498ira
    }

}
